<template>
  <div class="fr-card-body">
    <slot />
  </div>
</template>

<style lang="sass">

.fr-card-body
  @apply gap-2 flex flex-col p-2 flex-1 flex-nowrap

// #NEO-TAG
.neo
  .fr-card-body
    @apply bg-fr-gray-50

  &.dark
    .fr-card-body
      @apply bg-fr-gray-900
</style>
